import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-blog-starter/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p" {...{
        "className": "firstword"
      }}>{`Tasgheer`}</strong>{` comes from the word  `}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`صغَّر  يُصَّغِر  التَّصْغِيْر`}</code><br parentName="p"></br>{`
`}{`meaning to belittle or decrease. Its opposite is كَبَّر and comes under study of Sarf.`}</p>
    <br />
    <p>{`Oops did I hurl a technical Jargon at you !!! :/`}<br parentName="p"></br>{`
`}{`Don't worry lemme take a step back and refresh the definition of `}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Sarf`}</strong>{`  `}</p>
    <blockquote>
      <p parentName="blockquote">{`In Sarf, we study the word itself `}<strong parentName="p">{`NOT`}</strong>{` the word in the sentence`}</p>
    </blockquote>
    <p>{`i.e. how the word changes without looking at the sentence.`}</p>
    <br />
    <p>{`And since we are at it let me introduce its cousin, i.e. `}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Nahw`}</strong>{`  `}</p>
    <blockquote>
      <p parentName="blockquote">{`In Nahw, we study the word in the sentence.`}</p>
    </blockquote>
    <p>{`i.e. How this word is affected OR affected other words in the sentence. Example how the use of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`انّ`}</code>{` changes the sentence and so on ...`}</p>
    <br />
    <p><strong parentName="p">{`But a burning question that we all want an answer of is ...`}</strong></p>
    <h2>{`Why do we use Tasgheer?`}</h2>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Case 1`}</strong>{` `}<strong parentName="p" {...{
        "className": "heading2"
      }}>{`عندما نتكلم عن شيء صغير`}</strong><br parentName="p"></br>{`
`}{`Suppose we want to say small book, we can either say, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`كتابٌ صغير`}</code>{` Or its Tasgheer form `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`كُتيّب`}</code>{`.`}</p>
    <br />
    <p>{`But how we came up with this construction.`}<br parentName="p"></br>{`
`}{`Have patience I will add details :)`}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Reason 2`}</strong>{` `}<strong parentName="p" {...{
        "className": "heading2"
      }}>{`التحقير`}</strong><br parentName="p"></br>{`
`}{`When we want to insult someone/something, we can use Tasgheer.  `}</p>
    <br />
    <p>{`So in place of addressing a man by `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`رجُل`}</code>{` we say `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`رُجيل`}</code>{` meaning you are a small man ... lol`}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Reason 3`}</strong>{` `}<strong parentName="p" {...{
        "className": "heading2"
      }}>{`التَّحَبُّب`}</strong><br parentName="p"></br>{`
`}{`When we are addressing someone we like/love e.g. our son, spouse etc, we can use Tasgheer.  `}</p>
    <p>{`So in place of addressing a kid by `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`طِفْل`}</code>{` we say `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`طُفيل`}</code></p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Reason 4`}</strong>{` `}<strong parentName="p" {...{
        "className": "heading2"
      }}>{`تقريب الزمان والمكان`}</strong><br parentName="p"></br>{`
`}{`Sometimes we use it for showing the nearness of time Or place.`}<br parentName="p"></br>{`
`}{`For example to show that its just right before Asr i.e. a few moments before Asr we will say `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`قُبَيْل العصر`}</code>{` instead of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`قَبل العصر`}</code>{`. As `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`قَبل العصر`}</code>{` may be used for 2 hours before Asr`}</p>
    <br />
    <p>{`Similarly, to mean right before Masjid we say `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`قُبَيْل المسجد`}</code>{` instead of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`قبل المسجد`}</code>{`.`}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Reason 5`}</strong>{` `}<strong parentName="p" {...{
        "className": "heading2"
      }}>{`التعظيم`}</strong><br parentName="p"></br>{`
`}{`Rarely we use Tasgheer for التعظيم.`}<br parentName="p"></br>{`
`}{`For exammple addressing a man (رجُل) but calling him `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`رُجيل`}</code>{` out of respect.`}</p>
    <br />
    <p>{`That covers all the reasons I guess.  `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "33.74233128834356%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA60lEQVQY05VQ207CQBDt/3+RL0JvgPoAmFADJBRsoUTTqFw2e5mZHWdrIgnqgydnJyebc2ZnNuJreDnT1fvN4En4WH4g8V+I+Lf0ttXjohrP6ucXRf5/YS/g7kFCAgsIRBTu/HU4+KgjSiVAcgDaiRGNJQAJoeSNE3oH6EA6itMjRk2Wvg7zbRJXca+9H5HW+9GwThK2thnkuzxjY9qHu6p/26RJk8Zv0wmeVd3v7fMsMoeDPR6lfokwsrNsdJjfGjJGBKjztw2UYk8i3Ol02TnsxKy1nhXFerMpy3KxXK7K9Xy+0F0L/+PnPgGkZpBlBMToKgAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Reason to use Tasgheer",
          "title": "Reason to use Tasgheer",
          "src": "/static/9d44bfcd0153afb2619e0eda922bb75b/a6d36/tasgheer.png",
          "srcSet": ["/static/9d44bfcd0153afb2619e0eda922bb75b/222b7/tasgheer.png 163w", "/static/9d44bfcd0153afb2619e0eda922bb75b/ff46a/tasgheer.png 325w", "/static/9d44bfcd0153afb2619e0eda922bb75b/a6d36/tasgheer.png 650w", "/static/9d44bfcd0153afb2619e0eda922bb75b/bad1b/tasgheer.png 841w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <p>{`Moving on !!! Lets look into `}</p>
    <h2>{`Conditions required by an Ism to make it's Tasgheer`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "heading1"
        }}>{`أن يكون الاسم معرباً`}</strong><br parentName="li"></br>
        {`We use Tasgheer with Mo'rab Ism only (Not Fail or Harf)`}<br parentName="li"></br>
        <strong parentName="li" {...{
          "className": "exception"
        }}>{`Exception`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`ذا`}</code>{` has Tasgheer i.e. `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`ذَيَّا`}</code>{` even though it is اسم إشارة `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`مبني`}</strong></li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`الذي`}</code>{` has Tasgheer i.e. `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`اللَّذَيَّا`}</code>{` even though it is `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`مبني`}</strong></li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`التي`}</code>{` has Tasgheer i.e. `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`اللَّتَيَّا`}</code>{` even though it is `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`مبني`}</strong></li>
        </ul>
      </li>
    </ul>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "heading1"
        }}>{`أن يكون الاسم قابلاً للتصغير`}</strong><br parentName="li"></br>
        {`Ism should accept Tasgheer. There are certain Ism's for which we can't make Tasgheer for e.g.`}
        <ul parentName="li">
          <li parentName="ul">{`Names of Allah (أسماء الله)`}</li>
          <li parentName="ul">{`Names of prophet (أسماء الأنبياء)`}</li>
          <li parentName="ul">{`Names of Angels (أسماء الملائكة)`}</li>
        </ul>
      </li>
    </ul>
    <br />
    <p>{`Above are the two major rules. Now we will learn`}</p>
    <h2>{`How to make Tasgheer`}</h2>
    <p>{`To make Tasgheer, there are multiple forms (صيغ) in Arabic language. Let's look into it one by one beginning with`}</p>
    <h3>{`فُعَيْل`}</h3>
    <p>{`This form is used in following categories,`}<br parentName="p"></br>{`
`}<strong parentName="p" {...{
        "className": "heading1"
      }}>{`Category 1`}</strong>{` `}<strong parentName="p">{`Three letters Ism (الاسم الثلاثي)`}</strong><br parentName="p"></br>{`
`}<strong parentName="p" {...{
        "className": "heading2"
      }}>{`Transformation Rule of Tasgheer:`}</strong>{` Add Damma on first letter and ي after second letter.`}<br parentName="p"></br>{`
`}{`For example فلس has three letters, its tasgheer is فُليس`}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Category 2`}</strong>{` `}<strong parentName="p">{`Three letters feminine Ism`}</strong><br parentName="p"></br>{`
`}<strong parentName="p" {...{
        "className": "heading2"
      }}>{`Transformation Rule of Tasgheer:`}</strong>{` (Add Damma on first letter and ي after second letter) + add تاء التأنيث.`}<br parentName="p"></br>{`
`}{`For example Tasgheer of `}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`عَين`}</code>{` is `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`عُيينة`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`أُذُن`}</code>{` is `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`أُذُينة`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`قدر`}</code>{` is `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`قُدَيْرَة`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`نار`}</code>{` is `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`نُويرة`}</code>{` (here ا is changed to و)`}
        <blockquote parentName="li">
          <p parentName="blockquote">{`إذا كان الاسم المُصَغَّر مؤنثاً يجب أن نزيد تاء التأنيث`}</p>
        </blockquote>
      </li>
    </ul>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Category 3`}</strong>{` `}<strong parentName="p">{`Three letters Ism with second letter as Alif`}</strong><br parentName="p"></br>{`
`}<strong parentName="p" {...{
        "className": "heading2"
      }}>{`Transformation Rule of Tasgheer:`}</strong>{` (Add Damma on first letter and ي after second letter) + Change ا to its original letter  `}</p>
    <br />
    <p>{`For example, in باب we need to figure out whether ا is in place of و or ي i.e what is Asl of ا, for that we will   look into plural of باب which is أبواب. So through plural we know ا in باب is in place of و (i.e. باب is originally بوب). Hence, the tasgheer of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`باب`}</code>{` will be `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`بُوَيْب`}</code>{`.  `}</p>
    <br />
    <p>{`Similarly, in ناب its plural is أنياب. So we know ا in ناب is in place of ي (i.e. ناب is originally نيب). Hence,   the tasgheer of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ناب`}</code>{` will be `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`نُيَيْب`}</code></p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Category 4`}</strong>{` `}<strong parentName="p">{`Three letter Isms ending with ة`}</strong><br parentName="p"></br>{`
`}<strong parentName="p" {...{
        "className": "heading2"
      }}>{`Transformation Rule of Tasgheer:`}</strong>{` (Add Damma on first letter and ي after second letter) + add letter which Arabs have dropped.`}</p>
    <br />
    <p>{`For example in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`شفة`}</code>{` its originally `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`شَفْهَ`}</code>{` , its tasgheer is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`شُفَيْهَة`}</code>{` (we have added ة in the end as it is originally feminine).   `}</p>
    <br />
    <p>{`Similarly, from `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`شاة`}</code>{` we have `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`شُوَيهة`}</code>{` (Here, ا in شاة is originally و)`}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Category 5`}</strong>{` `}<strong parentName="p">{`Four letters Ism where second letter is Alif (اسم رباعي ثاني حرف فيه ألف)`}</strong><br parentName="p"></br>{`
`}<strong parentName="p" {...{
        "className": "heading2"
      }}>{`Transformation Rule of Tasgheer:`}</strong>{` (Add Damma on first letter and ي after second letter) + Change ا to و.  `}</p>
    <br />
    <p>{`For example راجل has four letters and second letter is ا, its tasgheer is رُويجل.  `}</p>
    <br />
    <p>{`Similarly, tasgheer of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`فارس`}</code>{` is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`فُويرس`}</code>{`. `}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Category 6`}</strong>{` `}<strong parentName="p">{`Four letters Ism where third letter is Alif`}</strong><br parentName="p"></br>{`
`}<strong parentName="p" {...{
        "className": "heading2"
      }}>{`Transformation Rule of Tasgheer:`}</strong>{` (Add Damma on first letter and ي after second letter) + Change ا to ي.  `}</p>
    <br />
    <p>{`For example كتاب has four letters and third letter is ا, its tasgheer is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`كُتَييب`}</code>{` Or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`كُتَيّب`}</code>{` (after Idhgham).  `}</p>
    <br />
    <p>{`Similarly, tasgheer of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`غزال`}</code>{` is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`غُزييل`}</code>{` Or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`غُزيِّل`}</code>{` (after Idhgham).`}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Category 7`}</strong>{` `}<strong parentName="p">{`Five letters Ism where fourth letter is Alif`}</strong><br parentName="p"></br>{`
`}<strong parentName="p" {...{
        "className": "heading2"
      }}>{`Transformation Rule of Tasgheer:`}</strong>{` (Add Damma on first letter and ي after second letter) + Change ا to ي.  `}</p>
    <br />
    <p>{`For example دينار has five letters and fourth letter is ا, its tasgheer is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`دنينير`}</code>{` `}</p>
    <br />
    <p>{` If you are wondering from where that extra ن came, let me assure you, you are not alone. If we look at plural of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`دينار`}</code>{`, which is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`دنانير`}</code>{` it also has an extra ن. Actually `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`دينار`}</code>{` was originally written as `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`دنَّار`}</code>{`. Now we can easily apply tasgheer rules to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`دنَّار`}</code>{` and make it `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`دنينير`}</code></p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Category 8`}</strong>{` `}<strong parentName="p">{`Five letters Isms`}</strong><br parentName="p"></br>{`
`}<strong parentName="p" {...{
        "className": "heading1"
      }}>{`Category 8a`}</strong>{` `}<strong parentName="p">{`If it has extra letters`}</strong><br parentName="p"></br>{`
`}<strong parentName="p" {...{
        "className": "heading2"
      }}>{`Transformation Rule of Tasgheer:`}</strong>{` (Add Damma on first letter and ي after second letter) + ٍٍRemove extra letter.  `}</p>
    <br />
    <p>{`For example `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`منطلق`}</code>{` has ن as extra letter its tasgheer is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`مُطيلِق`}</code>{`. Similarly,`}</p>
    <ul>
      <li parentName="ul">{`In `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`مرتزق`}</code>{` we have ت as extra letter its tasgheer is `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`مُرَيْزِق`}</code></li>
      <li parentName="ul">{`In `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`مستخرج`}</code>{` we have ت and س as extra letter its tasgheer is `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`مُخَيرِج`}</code></li>
    </ul>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Category 8b`}</strong>{` `}<strong parentName="p">{`If all letters are Original/Asl letters`}</strong><br parentName="p"></br>{`
`}<strong parentName="p" {...{
        "className": "heading2"
      }}>{`Transformation Rule of Tasgheer:`}</strong>{` (Add Damma on first letter and ي after second letter) + Remove last letter.  `}</p>
    <br />
    <p>{`For example سَفَرْجَل has all Asl letters its tasgheer is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`سُفَيْرِج`}</code>{` after removing ل at the end.`}</p>
    <br />
    <p><strong parentName="p">{`Side Note`}</strong>{` By extra letters I mean the letters that we add in three lettered verb to make form II, from III ... form X etc (verb forms).`}<br parentName="p"></br>{`
`}{`For e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`خرج`}</code>{` is three lettered verb and all its letters are original. To make it form IV we add extra أ in front i.e. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`أخرج`}</code>{` and for form X we add ا س ت i.e. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`استخرج`}</code>{`.`}<br parentName="p"></br>{`
`}{`There are only 10 extra letters, which are all letters of the word  `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`سألتمونيها`}</code></p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Category 9`}</strong>{` `}<strong parentName="p">{`Five letters Isms of form فعلان`}</strong><br parentName="p"></br>{`
`}<strong parentName="p" {...{
        "className": "heading1"
      }}>{`Category 9a`}</strong>{` `}<strong parentName="p">{`If it is Munsarif e.g. سِرْحانٌ`}</strong><br parentName="p"></br>{`
`}<strong parentName="p" {...{
        "className": "heading2"
      }}>{`Transformation Rule of Tasgheer:`}</strong>{` (Add Damma on first letter and ي after second letter) + Change ا to ي.  `}</p>
    <br />
    <p>{`For example `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`سِرْحانٌ`}</code>{` will become `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`سُرَيْحِين`}</code>{`  `}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Category 9b`}</strong>{` `}<strong parentName="p">{`If it is NOT Munsarif e.g. عثمانُ`}</strong><br parentName="p"></br>{`
`}<strong parentName="p" {...{
        "className": "heading2"
      }}>{`Transformation Rule of Tasgheer:`}</strong>{` Add Damma on first letter and ي after second letter.  `}</p>
    <br />
    <p>{`For example `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`عثمانُ`}</code>{` will become `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`عُثيمان`}</code>{`. Similarly, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`سكرانُ`}</code>{` will become `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`سُكيران`}</code>{`.`}</p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Category 10`}</strong>{` `}<strong parentName="p">{`Six letter Isms`}</strong><br parentName="p"></br>{`
`}<strong parentName="p" {...{
        "className": "heading2"
      }}>{`Transformation Rule of Tasgheer:`}</strong>{` Add Damma on first letter and ي after second letter.  `}</p>
    <br />
    <p>{`For example زعفران has six letters, its tasgheer is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`زُعيفران`}</code></p>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Category 11`}</strong>{` `}<strong parentName="p">{`Two letter Isms`}</strong><br parentName="p"></br>{`
`}<strong parentName="p" {...{
        "className": "heading2"
      }}>{`Transformation Rule of Tasgheer:`}</strong>{` (Add Damma on first letter and ي after second letter) + Add what is dropped by Arabs.  `}</p>
    <br />
    <p>{`For example `}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`دم`}</code>{` is originally `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`دَمَيٌ`}</code>{` so its tasgheer is `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`دُميي`}</code>{` OR `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`دُمَيَّ`}</code>{`.  `}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`أخ`}</code>{` is originally `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`أخو`}</code>{` so its tasgheer is `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`أُخيو`}</code>{` which is difficult to pronounce so we will convert و to ي i.e. `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`أُخيو`}</code>{` to `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`أُخيي`}</code>{` OR `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`أُخَيَّ`}</code>{`.  `}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`أب`}</code>{` is originally `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`أبو`}</code>{` so its tasgheer is `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`أُبيو`}</code>{` which is difficult to pronounce so we will convert و to ي again i.e. `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`أُبيو`}</code>{` to `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`أُبيي`}</code>{` OR `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`أُبَيَّ`}</code></li>
    </ul>
    <br />
    <h3>{`فُعَيعِل`}</h3>
    <p>{`This form is used in following category,`}<br parentName="p"></br>{`
`}<strong parentName="p" {...{
        "className": "heading1"
      }}>{`Category 1`}</strong>{` `}<strong parentName="p">{`Five letters Isms`}</strong><br parentName="p"></br>{`
`}<strong parentName="p" {...{
        "className": "heading1"
      }}>{`Category 1a`}</strong>{` `}<strong parentName="p">{`If it has extra letters`}</strong><br parentName="p"></br>{`
`}<strong parentName="p" {...{
        "className": "heading2"
      }}>{`Transformation Rule of Tasgheer:`}</strong>{` (Add Damma on first letter + ي after second and third letter) + remove extra letter.  `}</p>
    <br />
    <p>{`For example `}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`منطلق`}</code>{` has ن as extra letter its tasgheer is `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`مُطيليق`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`مرتزق`}</code>{` we have ت as extra letter its tasgheer is `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`مُرَيْزِيق`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`مستخرج`}</code>{` we have ت and س as extra letter its tasgheer is `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`مُخَيرِيج`}</code></li>
    </ul>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Category 1b`}</strong>{` `}<strong parentName="p">{`If all letters are Original/Asl letters`}</strong><br parentName="p"></br>{`
`}<strong parentName="p" {...{
        "className": "heading2"
      }}>{`Transformation Rule of Tasgheer:`}</strong>{` (Add Damma on first letter + ي after second and third letter) + remove last letter.  `}</p>
    <br />
    <p>{`For example سَفَرْجَل has all Asl letters its tasgheer is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`سُفَيْرِيج`}</code>{` after removing ل at the end`}</p>
    <h2>{`Exceptional Tasgheer`}</h2>
    <ul>
      <li parentName="ul">{`Tasgheer of `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`إنسان`}</code>{` is `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`أُنَيْسِيَان`}</code>{` instead of `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`أُنَيسِين`}</code></li>
      <li parentName="ul">{`Tasgheer of `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`مغرب`}</code>{` is `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`مُغَيْرِبَان`}</code>{` instead of `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`مُغيرب`}</code></li>
    </ul>
    <br />
    <h3>{`Conclusion`}</h3>
    <p>{`That's all folks I tried to organise the rules of Tasgheer to the best of my capabilites with proper explanations. But if you think that you are not convinced with the reasoning provided above, then it is what it is because `}<strong parentName="p">{`That is how Arabs said it !!!.`}</strong></p>
    <h2>{`Reference`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.qutoofacademy.com/",
          "rel": "nofollow noopener"
        }}>{`Qutoof Academy`}</a></li>
    </ul>
    {/* **فُعَيْعِيل**{: .heading1} */}

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      